<template>
  <div>
    <div class="d-flex align-items-center">
      <b-form-input
        v-model="filter.searchStringPatient"
        type="search"
        size="sm"
        placeholder="Введите ФИО пациента / консультирующего врача / законного представителя"
        class="border"
        @keyup.enter="$emit('search')"
      />
      <b-button
        id="popover-filter"
        v-b-tooltip.hover
        :variant="filterSelected ? 'primary' : 'light'"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="border border-primary p-1 ml-2"
        title="Дополнительные фильтры"
      >
        <b-icon icon="filter" />
      </b-button>
      <b-button
        v-b-tooltip.hover
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="p-1 ml-2"
        title="Поиск"
        @click="$emit('search')"
      >
        <b-icon icon="search" />
      </b-button>
    </div>

    <b-form-input
      v-model="filter.searchStringTargetPatient"
      type="search"
      size="sm"
      placeholder="Введите ФИО пациента (для доп профилей / консультаций врач-врач)"
      class="border mt-2"
      @keyup.enter="$emit('search')"
    />

    <div class="mt-2 d-flex align-items-end justify-content-between">
      <div class="d-flex">
        <b-form-input
          v-model="filter.phoneNumber"
          type="search"
          size="sm"
          placeholder="Номер телефона"
          class="border"
          @keyup.enter="$emit('search')"
        />
        <b-form-input
          v-model="filter.consultationId"
          type="search"
          size="sm"
          placeholder="ID консультации"
          class="border ml-3"
          @keyup.enter="$emit('search')"
        />
      </div>

      <div class="d-flex justify-content-end">
        <b-button
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          class="ml-auto border-0"
          size="sm"
          @click="resetAllFilters"
        >
          Очистить введенные данные
        </b-button>
      </div>

      <b-popover
        :show.sync="isPopoverOpen"
        target="popover-filter"
        triggers="click"
      >
        <div class="d-flex justify-content-end mb-2">
          <b-button
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="border border-danger p-0 ml-2"
            @click="isPopoverOpen = false"
          >
            <b-icon icon="x" />
          </b-button>
        </div>
        <div class="popover-inner p-2">
          <div>
            <div class="mb-3">
              <div class="mb-2 font-weight-bold">
                Дата создания
              </div>
              <div>
                <div class="mb-1">
                  <div class="small font-weight-bold mb-1">
                    От
                  </div>
                  <base-date-picker
                    v-model="filter.startCreateDate"
                    :max-date="maxDate"
                    class="mb-1"
                    @keyup.native.enter="$emit('search')"
                  />
                </div>
                <div>
                  <div class="small font-weight-bold mb-1">
                    До
                  </div>
                  <base-date-picker
                    v-model="filter.endCreateDate"
                    :max-date="maxDate"
                    @keyup.native.enter="$emit('search')"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="mb-2 font-weight-bold">
                Дата записи
              </div>
              <div>
                <div class="mb-1">
                  <div class="small font-weight-bold mb-1">
                    От
                  </div>
                  <base-date-picker
                    v-model="filter.startSlotDate"
                    :max-date="maxDate"
                    class="mb-1"
                    @keyup.native.enter="$emit('search')"
                  />
                </div>
                <div>
                  <div class="small font-weight-bold mb-1">
                    До
                  </div>
                  <base-date-picker
                    v-model="filter.endSlotDate"
                    :max-date="maxDate"
                    @keyup.native.enter="$emit('search')"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="mb-2 font-weight-bold">
                Дата закрытия
              </div>
              <div>
                <div class="mb-1">
                  <div class="small font-weight-bold mb-1">
                    От
                  </div>
                  <base-date-picker
                    v-model="filter.startCloseDate"
                    :max-date="maxDate"
                    class="mb-1"
                    @keyup.native.enter="$emit('search')"
                  />
                </div>
                <div>
                  <div class="small font-weight-bold mb-1">
                    До
                  </div>
                  <base-date-picker
                    v-model="filter.endCloseDate"
                    :max-date="maxDate"
                    @keyup.native.enter="$emit('search')"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="mb-2 font-weight-bold">
                Статус консультации
              </div>
              <b-form-select
                v-model="filter.status"
                :options="statuses"
                text-field="title"
                value-field="index"
                placeholder="Выберите статус"
              />
            </div>
            <div class="mb-3">
              <div class="mb-2 font-weight-bold">
                Режим консультации
              </div>
              <b-form-select
                v-model="filter.type"
                :options="types"
                text-field="title"
                value-field="index"
                placeholder="Выберите режим"
              />
            </div>
            <div class="mb-3">
              <div class="mb-2 font-weight-bold">
                Клиника
              </div>

              <base-async-select
                v-model="selectedClinic"
                :fetch-function="fetchClinics"
                placeholder="Выберите клинику"
                server-paginated
                loading-title="Загрузка клиник"
                no-options-title="Клиники не найдены"
                @change="changeClinic($event)"
              />
            </div>
            <div class="mb-3">
              <div class="mb-2 font-weight-bold">
                Врач
              </div>

              <base-async-select
                v-model="selectedDoctor"
                :fetch-function="fetchDoctors"
                placeholder="Выберите врача"
                server-paginated
                loading-title="Загрузка врачей"
                no-options-title="Врачи не найдены"
                @change="changeDoctor($event)"
              />
            </div>

            <div class="mb-3">
              <div class="mb-2 font-weight-bold">
                Специализация
              </div>

              <b-form-select
                v-model="filter.specializationId"
                :options="specializationsUpdated"
                text-field="name"
                type="search"
                value-field="id"
                placeholder="Выберите специализацию"
              />
            </div>

            <div class="d-flex align-items-center">
              <b-button
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                @click="applyFilter"
              >
                Применить
              </b-button>
              <b-button
                variant="danger"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="ml-2"
                @click="resetPopoverFilters"
              >
                Сбросить
              </b-button>
            </div>
          </div>
        </div>
      </b-popover>
    </div>
  </div>
</template>

<script>
// логика почти не изменялас. задача была - переверстать
import Bus from '@/eventBus';

import { CONSULTATION_STATUSES, CONSULTATION_STATUSES_FOR_CLINIC_ADMIN, CONSULTATION_TYPES } from '@/helpers/consts';
import { mixinRoles } from '@/mixins';
import { BaseAsyncSelect, BaseDatePicker } from '@/components/base';

export default {
  name: 'ConsultationsSearchPanelNew',
  components: {
    BaseAsyncSelect,
    BaseDatePicker,
  },
  mixins: [mixinRoles],
  model: {
    prop: 'filter',
    event: 'change',
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isPopoverOpen: false,
      types: CONSULTATION_TYPES,
      selectedDoctor: null,
      selectedClinic: null,
      specializations: [],
      maxDate: new Date(),
      filterKeys: [
        'startCreateDate',
        'endCreateDate',
        'startSlotDate',
        'endSlotDate',
        'startCloseDate',
        'endCloseDate',
        'status',
        'type',
        'clinicId',
        'doctorId',
        'specializationId',
      ],
    };
  },
  computed: {
    statuses() {
      // eslint-disable-next-line max-len
      const statuses = this.checkFeatureAccess({ name: 'Фильтр. Статус консультации. Сменить статусы (скрыто: Консилиум, Ожидает оплаты, Таймаут оплаты, Забронировано, Отмена бронирования)', url: '/consultations-control' })
        ? CONSULTATION_STATUSES_FOR_CLINIC_ADMIN : CONSULTATION_STATUSES;
      return statuses.sort((a, b) => a.title.localeCompare(b.title));
    },
    specializationsUpdated() {
      return [{
        id: null,
        name: 'Не выбрано',
      }, ...this.specializations];
    },
    filterSelected() {
      const filters = this.filterKeys.map((item) => this.filter[item]);
      return Object.values(filters).some((item) => item || item === 0);
    },
  },
  async created() {
    this.specializations = await this.fetchSpecializations('');
  },
  methods: {
    applyFilter() {
      this.$emit('search');
      this.isPopoverOpen = false;
    },
    togglePopover() {
      this.isPopoverOpen = !this.isPopoverOpen;
    },
    resetPopoverFilters() {
      Bus.$emit('consultations:reset-popover-filters');
      this.selectedClinic = null;
      this.selectedDoctor = null;
      this.$emit('search');
      this.isPopoverOpen = false;
    },
    resetAllFilters() {
      Bus.$emit('consultations:reset-all-filters');
    },
    async fetchSpecializations(query) {
      const data = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);

      return data;
    },
    async fetchDoctors({ query, skip, take }) {
      const doctors = await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
        skip,
        take,
        query,
        doctorSpecializationId: this.filter.specializationId ? this.filter.specializationId : null,
        save: false,
      });

      return doctors.map((doctor) => ({ id: doctor.id, name: doctor.fullName }));
    },
    async fetchClinics({ query, skip, take }) {
      const clinics = await this.$store.dispatch(this.$types.CLINICS_FETCH, {
        skip,
        take,
        query,
        filter: {
          query,
        },
        save: false,
      });

      return clinics.map((clinic) => ({ id: clinic.id, name: `${clinic.title}` }));
    },
    changeDoctor(doc) {
      this.filter.doctorId = doc ? doc.id : null;
    },
    changeClinic(clinic) {
      this.filter.clinicId = clinic ? clinic.id : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-inner {
  max-height: 400px;
  overflow: auto;
}

.popover {
  width: 350px !important;
  max-width: unset !important;
}
</style>
